import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { MainLayout } from '../src/MainLayout';
import { UserRole } from '../src/model/UserInfo';
import { HomePage } from '../src/pages/marketing/HomePage';
import { useUserStore } from '../src/store/user';

export default observer(() => {
  const user = useUserStore();
  const route = useRouter();

  const redirectToUrl = (url: string) => {
    if (typeof window === 'undefined') {
      // this redirect doesn't work on the server
      return null;
    }
    route.push(url);
    return null;
  };

  switch (user.getUserRole()) {
    case UserRole.Provider:
      return redirectToUrl(`/users/${user.getUserId()}/leads`);
    case UserRole.Client:
      return redirectToUrl(`/users/${user.getUserId()}/jobs`);
    case UserRole.Manager:
      return redirectToUrl(`/profile-settings/manage-clients`);
    default:
      return (
        <MainLayout>
          <HomePage />
        </MainLayout>
      );
  }
});
